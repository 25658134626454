var site = site || {};
var generic = generic || {};

(function ($) {
  window.site = site || {};
  site.offers = site.offers || {};
  site.offers.welcome15 = site.offers.welcome15 || {};

  Drupal.behaviors.welcome15V2 = {
    debug: false,
    offerCookie: 'welcome15',
    delayTrigger: '',
    templates: {
      form: 'welcome15_v2'
    },

    isStr: function (str) {
      return typeof str === 'string';
    },

    isFunc: function (func) {
      return typeof func === 'function';
    },

    isObj: function (mixed_var) {
      if (Object.prototype.toString.call(mixed_var) === '[object Array]') {
        return false;
      }

      return mixed_var !== null && typeof mixed_var === 'object';
    },

    getTemplateContent: function (key, data) {
      data = data || false;
      var Mustache = Mustache || {};
      var html = site.template.get({
        name: key,
        data: data
      });

      if (html.length === 0) {
        return $();
      }

      if (data === false || !$.isFunction(Mustache.render)) {
        return $(html);
      }

      return $(Mustache.render(html, data));
    },

    showOfferOnPage: function () {
      var self = this;
      var cookieName = self.offerCookie;
      var hasCookie = $.cookie(cookieName);
      var showOffer = false;
      var popupShowAtVisitCount =
        Drupal.settings.globals_variables.popup_show_at_visit_count || false;
      var markUp = self.getTemplateContent(self.templates.form);
      var trigger = $(markUp).data().trigger;

      self.delayTrigger = $(markUp).data().delayTrigger || '1000';

      if (!hasCookie) {
        if (trigger === true) {
          $.cookie(cookieName, '1', {
            expires: 14,
            path: '/'
          });

          return false;
        }
      }

      if (
        self.debug ||
        (!hasCookie && !Drupal.settings.common.show_welcome_every_session) ||
        (Drupal.settings.common.show_welcome_every_session &&
          !sessionStorage.getItem('mx_newsletter_session'))
      ) {
        showOffer = true;
      }

      if (popupShowAtVisitCount) {
        showOffer = false;
        $(document).on('RPC:RESULT', function () {
          if (
            !showOffer &&
            parseInt(site.trackingDataLayer.data['cp.utag_main__pn']) === popupShowAtVisitCount
          ) {
            self.showSignupForm();
            showOffer = true;
          }
        });
      }

      return showOffer;
    },

    attach: function (context, settings) {
      if (settings.hide_welcome15_overlay_onload) {
        return false;
      }
      var self = this;
      var cookieName = self.offerCookie;
      var showSignupPanel = self.showOfferOnPage() === true;

      if (showSignupPanel) {
        // Bring user attention by delay option
        setTimeout(function () {
          $(document).trigger('welcome15.trigger');
        }, self.delayTrigger);
      }

      $.cookie(cookieName, '1', {
        expires: 14,
        path: '/'
      });

      if (Drupal.settings.common.show_welcome_every_session) {
        sessionStorage.setItem('mx_newsletter_session', 'true');
      }
    }
  };
})(jQuery);
